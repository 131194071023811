import Banner from './components/BannerCom.vue';
import ApplicationScreen from './components/ApplicationScreen.vue';
import CooperationCom from './components/CooperationCom.vue';
import FooterCom from './components/FooterCom.vue';
export default {
  name: 'App',
  components: {
    Banner,
    ApplicationScreen,
    CooperationCom,
    FooterCom
  }
};