import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "page_contanier"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Banner = _resolveComponent("Banner");
  const _component_ApplicationScreen = _resolveComponent("ApplicationScreen");
  const _component_CooperationCom = _resolveComponent("CooperationCom");
  const _component_FooterCom = _resolveComponent("FooterCom");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Banner), _createVNode(_component_ApplicationScreen), _createVNode(_component_CooperationCom), _createVNode(_component_FooterCom)]);
}